import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo_white from '../../_style/img/logo_white_new.svg';
import infobbok_white from '../../_style/img/infobbok_white.svg';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
  //
  const { t } = useTranslation('common');
  const [ref, inView] = useInView();

  /**
   *
   */
  return (
    <div
      // className="ibb-footer"
      className={classNames('ibb-footer', {
        'ibb-ani__fade ibb-ani__delay2': inView,
        'ibb-ani__fade-out ibb-ani__hidden': !inView,
      })}
    >
      <div className="ibb-footer__wrap ibb-page__wrap" ref={ref}>
        <div className="ibb-footer__blok-logo">
          <div
            className={classNames('ibb-footer__logo', {
              'ibb-ani__to-right ibb-ani__delay2': inView,
              'ibb-ani__fade-out': !inView,
            })}
            // className="ibb-footer__logo"
          >
            <Link to="/">
              <img src={logo_white} alt="Homepage" /> <img src={infobbok_white} alt="Homepage" />
            </Link>
            <span>
              Портал для привлечения клиентов и автоматизации бизнеса. <br />
              Начните улучшать свой бизнес с технологиями InfoBbook.
            </span>
          </div>
          {/* <div className="ibb-footer__tariffs">
            <Link to="/tariffs">{t('Tariffs')} </Link>
            <br></br>
            <br></br>
            <Link to="/faq">{t('Frequently asked Questions')} </Link>
          </div> */}
          <div
            className={classNames('ibb-footer__links', {
              'ibb-ani__to-top ibb-ani__delay3': inView,
              'ibb-ani__fade-out': !inView,
            })}
            // className="ibb-footer__links"
          >
            <Link to="/faq" id="terms">
              {t('FAQ')}
            </Link>
            <br />
            <Link to="/terms" id="terms">
              {t('User Agreement')}
            </Link>
            <br />
            <Link to="/privacy-policy" id="protection">
              {t('Privacy policy')}
            </Link>
          </div>
          <div
            // className="ibb-footer__messangers"
            className={classNames('ibb-footer__messangers', {
              'ibb-ani__to-left ibb-ani__delay4': inView,
              'ibb-ani__fade-out': !inView,
            })}
          >
            <span>{t('Follow us on social networks')}</span>
            <a
              className="ibb-footer__icon facebook"
              href="https://www.facebook.com/"
              target={'_blank'}
              rel="nofollow"
            ></a>

            <a
              className="ibb-footer__icon telegram"
              href="https://telegram.org/"
              target="_blank"
              rel="nofollow"
            ></a>
          </div>
        </div>

        <div className="ibb-footer__blok">
          <span style={{ opacity: 0.5 }}>
            &copy; InfoBBook LTD, {new Date().getFullYear()} г. Все права защищены.
          </span>

          {/* <div className="ibb-footer__links">
            <Link to="/terms" id="terms">
              {t('User Agreement')}
            </Link>
            <Link to="/privacy-policy" id="protection">
              {t('Privacy policy')}
            </Link>
          </div> */}

          {/* <span>
            {t('Created in ')}
            <a href="https://bweb.studio/" target="_blank" rel="nofollow">
              Bweb.studio
            </a>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
