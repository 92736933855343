import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './_store';
import Routes from './Routes';
// import './style.css';
import { Footer, Header } from './UI';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import en from 'antd/lib/locale/en_US';
import ru from 'antd/lib/locale/ru_RU';
import he from 'antd/lib/locale/he_IL';
import { Locale } from 'antd/lib/locale-provider';
import { ConfigProvider } from 'antd';

//
const locales: { [key: string]: Locale } = {
  ru,
  en,
  he,
};

/**
 * APP Component
 */
const App = () => {
  //
  const { i18n } = useTranslation('common');

  //
  const [cookies] = useCookies(['token']);

  if (cookies.token) {
    localStorage.setItem('token', cookies.token);
  } else {
    // localStorage.removeItem('token');
  }

  //
  localStorage.setItem('language', i18n.language);

  /** */
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider
          direction={i18n.language === 'he' ? 'rtl' : 'ltr'}
          locale={locales[i18n.language]}
        >
          <Header />
          <Routes />
          <Footer />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
