import { Image, Popover, Row } from 'antd';
import curRub from '../../_style/ico/currency_rub.svg';
import curUsd from '../../_style/ico/currency_usd.svg';
import curIls from '../../_style/ico/currency_ils.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// select languages
const currences = [
  {
    value: 'RUB',
    ico: curRub,
  },
  {
    value: 'USD',
    ico: curUsd,
  },
  {
    value: 'ILS',
    ico: curIls,
  },
];

//
const langImageStyle = {
  borderRadius: '50%',
  width: 24,
  height: 24,
  // border: '1px solid #E1E6F5',
};

const CurrencySelector = () => {
  //
  const { i18n } = useTranslation();

  //
  const [opened, setOpened] = useState(false);

  //
  const [current, setCurrent] = useState('RUB');

  return (
    <Popover
      trigger="click"
      placement="bottom"
      onVisibleChange={(value) => {
        setOpened(value);
      }}
      visible={opened}
      getPopupContainer={() => {
        return document.querySelector('#header') || document.body;
      }}
      content={
        <>
          {currences
            .filter((c) => c.value !== current)
            .map((item, key) => (
              <Row
                key={key}
                align="middle"
                onClick={() => {
                  setOpened((state) => !state);
                  setCurrent(item.value);
                }}
                style={{ paddingTop: key !== 0 ? 15 : 0, cursor: 'pointer' }}
              >
                <Image
                  src={item.ico}
                  preview={false}
                  alt={`large${item.value}`}
                  style={langImageStyle}
                />
                <span style={{ padding: '0 8px' }}>{item.value}</span>
              </Row>
            ))}
        </>
      }
    >
      <Row
        align="middle"
        style={{
          cursor: 'pointer',
          padding: 5,
          margin: window.matchMedia('(max-width: 960px)').matches ? '0px 10px 0 0' : '0 0 0 10px',
        }}
      >
        <Image
          src={currences.find((item) => item.value === current)?.ico}
          preview={false}
          alt={'largeIcon'}
          style={langImageStyle}
        />

        <span style={{ padding: '0 8px' }}>{current}</span>
        <svg
          style={{
            transition: 'all 0.2s',
            transform: opened ? 'scaleY(-1)' : 'scaleY(1)',
          }}
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.49752 0.242641L4.25488 4.48528L0.0122422 0.242641H8.49752Z"
            fill={opened ? '#b7002c' : '#020737'}
          />
        </svg>
      </Row>
    </Popover>
  );
};

export default CurrencySelector;
