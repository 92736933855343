import React from 'react';
import { createStore, combineReducers } from 'redux';
import { contactReducer } from './contact/reducers';
import { historyReducer } from './history/reducers';
import { userReducer, UserState } from './user/reducers';

/**
 *
 */
const rootReducer = combineReducers({
  history: historyReducer,
  user: userReducer,
  contact: contactReducer,
});

/**
 * Export store
 */
const store = createStore(rootReducer);

/**
 *
 */
export interface StoreType {
  history: React.ReactNode[];
  user: UserState;
  contact: { showModal: boolean };
}

/**
 *
 */
export default store;
