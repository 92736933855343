//
export const ROUTE = process.env.REACT_APP_API_ROUTE;

export const PUBLIC_URL = 'https://builder.infobbook.com/ru/brand/852501/';

/** */
export const API = async (endpoint: string, method = 'GET', body = {}) => {
  const token = localStorage.getItem('token');
  const browserLang = navigator.language;
  const language = localStorage.getItem('language');

  const fetchObj: RequestInit = {
    method: method,
    credentials: 'omit',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      language: language || browserLang,
    }),
  };

  if (method.toUpperCase() !== 'GET' && body) {
    fetchObj.body = JSON.stringify(body);
  }
  if (token) {
    fetchObj.headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      language: language || browserLang,
    });
  }

  try {
    const response = await fetch(ROUTE + endpoint, fetchObj);
    // console.log(
    //   'response.headers',
    //   parseInt(response.headers.get('x-pagination-total-count') || '0')
    // );

    // for (const value of response.headers.entries() as any) {
    //   console.log(value);
    // }
    if (response.ok && response.status >= 200 && response.status < 204) {
      if (response.headers.get('x-pagination-total-count')) {
        const data = await response.json();
        return new Promise((resolve, reject) =>
          resolve({
            data,
            total: parseInt(response.headers.get('x-pagination-total-count') || '0'),
          })
        );
      }
      return response.json();
    } else if (response.ok && response.status === 204) {
      return {};
    } else {
      if (response.status === 422) {
        return { warning: await response.json() };
      }
      // console.warn('API status error:', response);
      return { error: await response.json() };
      // throw new Error(response.statusText);
    }
  } catch (error: any) {
    console.error('API error:', error.message);
    // throw new Error(error);
    return {
      error: error.message,
    };
  }
};
