import moment from 'moment';
import 'moment/min/locales.min';

export const asCurrency = (value: number, locale: string, currency: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
};

export const asDate = (date: Date, locale: string) => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const asTime = (date: Date, locale: string) => {
  return date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
};

export const asRelativeTime = (date: Date, locale: string) => {
  moment.locale(locale);
  return moment(date).fromNow();
};

export const asColoredText = (text: string, colored: string) => {
  return text.replace(colored, `<span>${colored}</span>`);
};
