import { Image, Popover, Row } from 'antd';
import largeRU from '../../_style/ico/lang_ru.svg';
import largeEN from '../../_style/ico/lang_en.svg';
import largeHE from '../../_style/ico/lang_he.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// select languages
const languages = [
  {
    value: 'Ru',
    ico: largeRU,
  },
  {
    value: 'En',
    ico: largeEN,
  },
  {
    value: 'He',
    ico: largeHE,
  },
];

//
const langImageStyle = {
  borderRadius: '50%',
  width: 24,
  height: 24,
  border: '1px solid #E1E6F5',
};

const LanguagesSelector = () => {
  //
  const { i18n } = useTranslation();

  //
  const [opened, setOpened] = useState(false);
  // a === b === c;
  // c != a;
  return (
    <Popover
      trigger="click"
      placement="bottom"
      onVisibleChange={(value) => {
        setOpened(value);
      }}
      visible={opened}
      getPopupContainer={() => {
        return document.querySelector('#header') || document.body;
      }}
      className="ibb-languages-selector"
      content={
        <>
          {languages
            .filter((lang) => lang.value.toLowerCase() !== i18n.language)
            .map((item, key) => (
              <Row
                key={key}
                align="middle"
                onClick={() => {
                  setOpened((state) => !state);
                  i18n.changeLanguage(item.value.toLowerCase());
                }}
                style={{ paddingBottom: key === 0 ? 15 : 0, cursor: 'pointer' }}
              >
                <Image
                  src={item.ico}
                  preview={false}
                  alt={`large${item.value}`}
                  style={langImageStyle}
                />
                <span style={{ padding: '0 8px' }}>{item.value}</span>
              </Row>
            ))}
        </>
      }
    >
      <Row
        align="middle"
        style={{
          cursor: 'pointer',
          // padding: 5,
          margin: window.outerWidth < 1200 ? '0px 10px 0 0' : '0',
        }}
      >
        <Image
          src={languages.filter((larg) => larg.value.toLowerCase() === i18n.language)[0].ico}
          preview={false}
          alt={'largeIcon'}
          style={langImageStyle}
        />

        <span style={{ padding: '0 8px' }}>
          {i18n.language[0].toUpperCase() + i18n.language.slice(1)}
        </span>
        <svg
          style={{
            transition: 'all 0.2s',
            transform: opened ? 'scaleY(-1)' : 'scaleY(1)',
          }}
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.49752 0.242641L4.25488 4.48528L0.0122422 0.242641H8.49752Z"
            fill={opened ? '#b7002c' : '#020737'}
          />
        </svg>
      </Row>
    </Popover>
  );
};

export default LanguagesSelector;
