import { userActionsType } from './actions';
import { StateType } from 'typesafe-actions';
// import avatar from '../../_style/ico/avatar.svg';
import avatar from '../../_style/img/avatar.jpg';

interface TypeNetworks {
  telegram?: string;
  viber?: string;
  facebook?: string;
  instagram?: string;
  whatsApp?: string;
}

export type TypeSocialLink = {
  name: string;
  link: string;
  publish: boolean;
};

/**
 * Данные по пользователю
 */
export interface UserState {
  id: number;
  firstName: string;
  lastName: string;
  first_name: string;
  last_name: string;
  email: string;
  spareEmail?: string;
  imageUrl?: string;
  phone: string;
  tariffId: number;
  createdAt?: number;
  passwd?: string;
  service?: string;
  coaching?: string;
  videoUrl: string | null;
  videoAvatar: string | null;
  business: string[];
  description: string;
  socials: TypeSocialLink[];
  contacts: TypeSocialLink[];
  socialNetworks?: TypeNetworks;
  ballance: number;
  filesUsage: number;
}

/**
 * Initial state
 */
const INITIAL_USER_STATE: UserState = {
  id: 0,
  firstName: '',
  lastName: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  description: '',
  imageUrl: avatar,
  tariffId: -1,
  createdAt: 1621841998,
  business: [],
  ballance: 0,
  filesUsage: 0,
  socials: [],
  contacts: [],
  videoUrl: null,
  videoAvatar: null,
};

/**
 * Root reducer
 *
 * @param state prev state
 * @param action current action
 */
export function userReducer(user = INITIAL_USER_STATE, action: userActionsType) {
  switch (action.type) {
    case 'SET_USER':
      return { ...user, ...action.payload };
    case 'CLEAR_USER':
      return INITIAL_USER_STATE;
    default:
      return user;
  }
}

export type userReducerType = StateType<typeof userReducer>;
