import { historyActionsType } from './actions';
import { StateType } from 'typesafe-actions';

/**
 * Initial state
 */
const INITIAL_STATE = {
  showModal: false,
};

/**
 * Root reducer
 *
 * @param state prev state
 * @param action current action
 */
export function contactReducer(contact = INITIAL_STATE, action: historyActionsType) {
  switch (action.type) {
    case 'SHOW':
      return {
        showModal: true,
      };
    case 'HIDE':
      return {
        showModal: false,
      };
    default:
      return contact;
  }
}

export type historyReducerType = StateType<typeof contactReducer>;
