import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
// import HomePage from './pages/HomePage';

const SignupPage = React.lazy(() => import('./pages/SignupPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const FAQPage = React.lazy(() => import('./pages/FAQPage'));
const TariffsPage = React.lazy(() => import('./pages/TariffsPage'));
const UsersPage = React.lazy(() => import('./pages/UsersPage'));
// const UserInfoPage = React.lazy(() => import('./pages/UserInfoPage'));
const TermsPage = React.lazy(() => import('./pages/TermsPage'));
const TermPage = React.lazy(() => import('./pages/TermsPage/TermPage'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicyPage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
const UserPage = React.lazy(() => import('./pages/UserPage'));

/**
 *
 */
const Routes = () => (
  <Suspense fallback={<div></div>}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/login" component={SignupPage} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/tariffs" component={TariffsPage} />
      <Route exact path="/terms" component={TermsPage} />
      <Route path="/terms/:term" component={TermPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/users" component={UsersPage} />
      <Route path="/users/:userId/:contactId" component={UserPage} />
      <Route path="/users/:userId" component={UserPage} />
      {/* <Route path="/user" exact component={UserPage} /> */}
      <Route render={() => <NotFoundPage />} />
    </Switch>
  </Suspense>
);

export default Routes;
