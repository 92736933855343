import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../_style/img/ibb_logo.png';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '../../_store';
import defaultAvatar from '../../_style/ico/avatar.svg';
import { LanguagesSelector, CurrencySelector, UserMenu } from '..';
import { Badge, Button, Col, Row } from 'antd';
import { useInView } from 'react-intersection-observer';
import Avatar from 'antd/lib/avatar/avatar';
import { useCookies } from 'react-cookie';

/** */
const Header = () => {
  //
  const { t } = useTranslation('common');

  //
  const location = useLocation();

  //
  const user = useSelector((state: StoreType) => state.user);

  //
  const dispatch = useDispatch();

  const { ref, inView } = useInView();
  //
  const [open, setOpen] = useState(false);
  const [openOffice, setOpenOffice] = useState(false);
  const [hamburgerOpened, setHamburgerOpened] = useState(false);

  const [isHomepage, setIsHomepage] = useState(location.pathname === '/');

  //
  const [cookies] = useCookies(['ref', 'contact']);

  // переключатель меню
  const toggle = () => {
    //
    setOpen(!open);
    if (openOffice) {
      toggleOffice();
    }
  };
  const toggleOffice = () => {
    //
    setOpenOffice(!openOffice);
    if (open) {
      toggle();
    }
  };

  //
  const mainMenu = [
    {
      title: 'About as',
      route: '/#ibb-home__about-block',
      external: false,
    },
    {
      title: 'Advantages',
      route: '/#ibb-benefits-anchor',
      external: false,
    },
    {
      title: 'Users',
      route: '/users',
      external: true,
    },
    {
      title: 'Сontacts',
      route: '/#ibb-contacts-anchor',
      external: false,
    },
  ];

  useEffect(() => {
    setIsHomepage(location.pathname === '/');
  }, [location.pathname]);

  /**
   *
   */
  return (
    <>
      <div
        id="header"
        className={classNames('ibb-header', {
          active: !inView || hamburgerOpened || !isHomepage,
        })}
        // className="ibb-header"
      >
        <div className="ibb-page__wrap">
          <div className="ibb-header__wrap">
            <Link
              to="/"
              className={classNames('ibb-header__logo', {
                // disactive: hamburgerOpened
              })}
              onClick={() => {
                const element = document.querySelector('#react-scroll-wheel-handler');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                } else {
                  window.scrollTo({ top: 0 });
                }
              }}
            >
              <img src={logo} alt="InfoBBook logo" />
            </Link>

            <nav className={classNames('ibb-header__menu', { active: hamburgerOpened })}>
              {mainMenu.map((link, key) => {
                if (cookies.contact && link.title === 'Users') {
                  return <></>;
                }
                // if (link.external) {
                if (cookies.ref && link.external && !cookies.contact) {
                  return (
                    <a key={key} onClick={() => dispatch({ type: 'SHOW' })}>
                      {t(link.title)}
                    </a>
                  );
                }
                return (
                  <Link key={key} to={link.route} onClick={() => setHamburgerOpened(false)}>
                    {t(link.title)}
                  </Link>
                );
                // }

                // return (
                //   <a
                //     href={link.route}
                //     onClick={() => {
                //       setHamburgerOpened(false);
                //     }}
                //     key={key}
                //   >
                //     {t(link.title)}
                //   </a>
                // );
              })}
              {/* {!user.id && window.outerWidth < 640 && (
                <>
                  <Link className="ibb__btn white" to={'/login'}>
                    {t('Login')}
                  </Link>
                </>
              )} */}

              {user.id > 0 && hamburgerOpened && window.outerWidth < 1200 && (
                <div style={{ display: 'flex', marginBottom: 20 }}>
                  {window.outerWidth > 1200 && (
                    <Col>
                      <CurrencySelector />
                    </Col>
                  )}

                  <Col>
                    <UserMenu />
                  </Col>
                </div>
              )}
              {(window.outerWidth > 1200 || window.outerWidth < 640) && (
                <Col
                  className={classNames('ibb-header__languages', {
                    menu: hamburgerOpened,
                  })}
                  onClick={() => {
                    toggle();
                  }}
                  style={{ display: 'flex' }}
                >
                  <LanguagesSelector />
                  <CurrencySelector />
                </Col>
              )}
            </nav>

            {window.outerWidth < 1200 && window.outerWidth > 640 && (
              <div
                className="ibb-header__languages-tab"
                // style={{ display: 'flex', marginLeft: 'auto' }}
              >
                <CurrencySelector />
                <LanguagesSelector />
              </div>
            )}

            {user.id > 0 && window.outerWidth > 1200 && (
              <>
                <Col>
                  <Row>
                    <Button
                      type="link"
                      style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                      href={process.env.REACT_APP_PORTAL_DOMAIN + '/'}
                    >
                      <Badge dot status="success">
                        <Avatar
                          style={{
                            backgroundColor: '#fff',
                            verticalAlign: 'middle',
                            border: '1px solid #E1E6F5',
                            margin: '0 10px',
                          }}
                          size="large"
                          src={
                            <img
                              src={user.imageUrl || defaultAvatar}
                              style={{ objectPosition: 'center', objectFit: 'cover' }}
                            />
                          }
                        />
                      </Badge>

                      <span
                        style={{
                          maxWidth: 80,
                          color: '#020737',
                          fontWeight: 'normal',
                          whiteSpace: 'initial',
                          lineHeight: 1.2,
                          textAlign: 'initial',
                        }}
                      >{`${user.first_name} ${user.last_name}`}</span>
                    </Button>

                    {/* <Col>
                <CurrencySelector />
              </Col> */}

                    <UserMenu />
                  </Row>
                </Col>
              </>
            )}

            {/* <div
            className={classNames(
              `ibb-header__languages ${
                i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)
              }`,
              {
                active: open,
                menu: hamburgerOpened,
              }
            )}
            onClick={() => {
              toggle();
            }}
          >
            <span>{i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)}</span>

            <div className="ibb-header__languages-wraper">
              <div
                className={classNames('ibb-header__languages-shadow', { active: open })}
                onClick={() => toggle()}
              />
              <div className={classNames('ibb-header__languages_list', { active: open })}>
                {['Ru', 'En', 'He'].map((lang) =>
                  i18n.language !== lang.toLowerCase() ? (
                    <button
                      key={lang}
                      onClick={() => {
                        i18n.changeLanguage(lang.toLowerCase());
                      }}
                      className={classNames('ibb-header__btn', lang)}
                    >
                      {lang}
                    </button>
                  ) : (
                    <React.Fragment key={lang}></React.Fragment>
                  )
                )}
              </div>
            </div>
          </div> */}

            <div className="ibb-header__inside">
              {!user.id && (
                <>
                  {cookies.ref && !cookies.contact ? (
                    <a
                      className={`ibb__btn-new ${
                        window.location.pathname === '/login' ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        dispatch({ type: 'SHOW' });
                      }}
                    >
                      {t('Login')}
                    </a>
                  ) : (
                    <Link
                      className={`ibb__btn-new ${
                        window.location.pathname === '/login' ? 'disabled' : ''
                      }`}
                      to={'/login'}
                    >
                      {t('Login')}
                    </Link>
                  )}
                </>
              )}
              <button
                className={classNames('ibb-header__hamburger', { active: hamburgerOpened })}
                onClick={() => setHamburgerOpened((s) => !s)}
              >
                <div></div>
                <div></div>
                <div></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref} className="ibb-header__in-view" />
    </>
  );
};

export default Header;
